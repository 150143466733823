
import './topNav.css';
import { FaCircleArrowRight } from "react-icons/fa6";

function TopNav() {
  return (
    <div className="topNav">
      <div className='flex justify-between h-[80px] items-center
      mx-2 
      sm:mx-10
      md:mx-20'>
        <div>
          <p>
            Dr. Pooja Reddy
          </p>
        </div>
        <div className='applyNow p-2 rounded-full 
        flex justify-between items-center gap-2 
        sm:hover:scale-95 sm:transform sm:transition sm:hover:cursor-pointer'>
          <p>
            Apply Now
          </p>
          <FaCircleArrowRight size="1.5rem"/>
        </div>
      </div>
    </div>
  );
}

export default TopNav;
