
import './landingPage.css';
import { FaCircleArrowRight } from "react-icons/fa6";
// import { BsStar } from "react-icons/bs";
import { BiSolidCalendarHeart } from "react-icons/bi";
import { BsHouseHeartFill } from "react-icons/bs";
import { MdStars } from "react-icons/md";

function LandingPage() {
    return(
        <div className='landingPage mt-[10dvh] h-[100%]'>
            <div className="flex flex-col items-center justify-start px-4">
                <h1 className="mt-10 text-4xl text-center text-nuowoOrange lazyText">Your Skin and Hair Personal Trainer</h1>
                <br></br>
                <h3 className="text-2xl text-center text-nuowoOrange lazyText">6-Month Transformation Programs. No Shortcuts, Just Real Results.</h3>
                <br></br>
                <div className="ctaGroup flex flex-col items-center gap-2">
                    <div className='lazy1sLoad p-3 border-2 border-nuowoOrange rounded-full
                    flex items-center gap-2'>
                        <p><a href="#howItWorks">Explore Programs</a></p>
                        <FaCircleArrowRight className="orageIcon" size="1.5rem"/>
                    </div>
                    <div className='lazy1sLoad flex items-center gap-2
                    bg-nuowoOrange rounded-full p-3'>
                        <p>Apply Now</p>
                        <FaCircleArrowRight size="1.5rem"/>    
                    </div>
                    
                </div>
            </div>
            <br></br>
            <div className='keyFeatures px-10 flex flex-col gap-4'>
                <div className='lazy15sLoad p-4 bg-nuowoTealAlpha rounded-xl
                flex flex-col items-center'>
                    <MdStars size="2.5rem" className='orageIcon' />
                    <strong className='text-center'>World-Class Equipment</strong>
                    <p className='text-center break-normal'>
                        Experience the best with US FDA-approved, advanced technology—the best you’ll find in the city.
                    </p>
                </div>
                <div className='lazy15sLoad p-4 bg-nuowoTealAlpha rounded-xl
                flex flex-col items-center'>
                    <BiSolidCalendarHeart size="2.5rem" className='orageIcon' />
                    <strong className='text-center'>More Than 2 Treatments per Month</strong>
                    <p className='text-center break-normal'>
                    Transform faster with frequent, consistent sessions every month.
                    </p>
                </div>
                <div className='p-4 bg-nuowoTealAlpha rounded-xl
                flex flex-col items-center'>
                    <BsHouseHeartFill size="2.5rem" className='orageIcon' />
                    <strong className='text-center'>At-Home Services</strong>
                    <p className='text-center break-normal'>
                    Busy schedule? We bring professional care right to your doorstep.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default LandingPage;