import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TopNav from './components/shared/topNav';
import LandingPage from './components/pages/LandingPage/landingPage';
import AboutProgram from './components/pages/AboutProgram/aboutProgram';
import DontJoin from './components/pages/DontJoin/dontJoin';
import HowItWorks from './components/pages/HowItWorks/howItWorks';
import Testimonial from './components/pages/Testimonials/testimonial';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <TopNav />
    <LandingPage />
    <Testimonial />
    <AboutProgram />
    <DontJoin />
    <HowItWorks />
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
