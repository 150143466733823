
function AboutProgram() {
    return(
        <div className="aboutProgram px-6 mt-10">
            <div className="programDetails flex flex-col justify-center align-middle">
                <h1 className="text-center text-2xl text-nuowoOrange"><strong>About Transformation Program</strong></h1>
                <br></br>
                <p className="text-center text-sm">Each program is designed to deliver real results 
                    without shortcuts.We blend medical and procedural treatments 
                    (cumulative) with lifestyle modifications and dietary guidance 
                    (holistic), personalized care, and wellness activities to support your 
                    journey.
                </p>
            </div>
            <br></br>
            <br></br>
            <div className="highlights">
                <h1 className="text-center text-2xl text-nuowoOrange"><strong>Treatment Highlights</strong></h1>
                <br></br>
                <div className='flex flex-col gap-4'>
                <div className='p-4 bg-nuowoTealAlpha rounded-xl
                flex flex-col items-center'>
                    {/* <MdStars size="2.5rem" /> */}
                    <strong className='text-center text-nuowoOrange'>Duration</strong>
                    <p className='text-center break-normal'>
                        Comprehensive 6-month program with more than 2 treatments per month.
                    </p>
                </div>
                <div className='p-4 bg-nuowoTealAlpha rounded-xl
                flex flex-col items-center'>
                    {/* <BiSolidCalendarHeart size="2.5rem" /> */}
                    <strong className='text-center text-nuowoOrange'>Secure App Interface / Convenient Mobile App</strong>
                    <p className='text-center break-normal'>
                        Easy scheduling, personal progress tracking, privacy prioritized, proprietary technology. 
                    </p>
                </div>
                <div className='p-4 bg-nuowoTealAlpha rounded-xl
                flex flex-col items-center'>
                    {/* <BsHouseHeartFill size="2.5rem" /> */}
                    <strong className='text-center text-nuowoOrange'>Medical + Holistic approach </strong>
                    <p className='text-center break-normal'>
                        Medications as needed, Nutritionist Guidance, Yoga Sessions, Mindfulness practices.    
                    </p>
                </div>
            </div>
            </div>
        </div>
    )
}

export default AboutProgram;