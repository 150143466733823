import { RxCrossCircled } from "react-icons/rx";

function DontJoin() {
    return (
        <div className="dontJoin h-[100%] mt-8">
            <div className="flex flex-col h-[100%] px-6 justify-evenly gap-4">
                <h1 className="text-3xl text-center text-nuowoOrange">Don't join the program</h1>
                <div className="items flex flex-col gap-4">
                    <div className="flex items-center gap-4 bg-nuowoTealAlpha p-3
                    rounded-2xl">
                        <RxCrossCircled size="1.25rem" className="flex-shrink-0 flex-grow-0 text-nuowoOrange"/>
                        <p className="text-start">If you think cosmetologist is same as dermatologist. </p>
                    </div>

                    <div className="flex items-center gap-4 bg-nuowoTealAlpha p-3
                    rounded-2xl">
                        <RxCrossCircled size="1.25rem" className="flex-shrink-0 flex-grow-0 text-nuowoOrange"/>
                        <p className="text-start">If you believe saloon facials are good for you. </p>
                    </div>

                    <div className="flex items-center gap-4 bg-nuowoTealAlpha p-3
                    rounded-2xl">
                        <RxCrossCircled size="1.25rem" className="flex-shrink-0 flex-grow-0 text-nuowoOrange"/>
                        <p className="text-start">If you think taking care of skin and hair is just for outer beauty. </p>
                    </div>

                    <div className="flex items-center gap-4 bg-nuowoTealAlpha p-3
                    rounded-2xl">
                        <RxCrossCircled size="1.25rem" className="flex-shrink-0 flex-grow-0 text-nuowoOrange"/>
                        <p className="text-start">If you are looking for results just with products. </p>
                    </div>

                    <div className="flex items-center gap-4 bg-nuowoTealAlpha p-3
                    rounded-2xl">
                        <RxCrossCircled size="1.25rem" className="flex-shrink-0 flex-grow-0 text-nuowoOrange"/>
                        <p className="text-start">If you cannot commit yourself to changes in routine. </p>
                    </div>

                    <div id="howItWorks" className="flex items-center gap-4 bg-nuowoTealAlpha p-3
                    rounded-2xl">
                        <RxCrossCircled size="1.25rem" className="flex-shrink-0 flex-grow-0 text-nuowoOrange"/>
                        <p className="text-start">If you are expecting instant results. </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DontJoin;