import React, {useEffect} from "react";
import "./howItWorks.css";


function HowItWorks() {

    const handleScroll = () => {

        const element = document.getElementById("scrollContent");
        const clientHeight = document.documentElement.clientHeight/1.15;
        const scrollBar = document.getElementById("workProgress");
        const scrollHeight = ((clientHeight - scrollBar.getBoundingClientRect().top)/clientHeight) * 100;

        if(scrollHeight <= 100 && scrollHeight > 0){
            document.getElementById("myBar").style.height = scrollHeight + "%";
        }else if(scrollHeight > 100){
            document.getElementById("myBar").style.height =  "100%";
        }

        element.childNodes.forEach((child) => {
            if(child.getBoundingClientRect().top < clientHeight){
                
                if(child.hasChildNodes()){
                    child.classList.remove("opacity-50");
                    child.firstChild.classList.add("inRangeBg");
                    child.lastChild.classList.add("inRange");
                    child.firstChild.classList.remove("outRangeBg");
                    child.lastChild.classList.remove("outRange");
                }
            }else{
                if(child.hasChildNodes()){
                    child.classList.add("opacity-50");
                    child.firstChild.classList.remove("inRangeBg");
                    child.lastChild.classList.remove("inRange");
                    child.firstChild.classList.add("outRangeBg");
                    child.lastChild.classList.add("outRange");
                }
            }
        })
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return() => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, []);

    return (
        <div className="howItWorks pb-12 mt-8">
            <div>
                <h1 className="text-3xl text-center">How It Works?</h1>
                <div className="flex h-[100%] py-10 px-6 gap-x-6">
                    <div className="workProgress" id="workProgress">
                        <div className="progress-container h-full w-[3px] bg-slate-400 rounded-full">
                            <div className="progress-bar h-[0dvh] bg-nuowoOrange w-full" id="myBar"></div>
                        </div>
                    </div>
                    <div id="scrollContent" className="content flex flex-col justify-evenly rounded-t-full gap-4">
                       <div className="flex flex-col gap-2">
                            <h3 className="text-center p-[15px]"><strong>Apply and Connect</strong></h3>
                            <p className="pl-4">Submit your application, and we'll schedule a video or in-person interview. Together, we'll discuss your goals and help you choose the right program.</p>
                       </div>

                       <div className="flex flex-col gap-2">
                            <h3 className="text-center p-[15px]"><strong>Acceptance Confirmation</strong></h3>
                            <p className="pl-4">If you're selected, we'll email you to confirm your place in the program.</p>
                       </div>

                       <div className="flex flex-col gap-2">
                            <h3 className="text-center p-[15px]"><strong>Comprehensive Consultation</strong></h3>
                            <p className="pl-4">Once you confirm, you'll have a thorough 60-minute in-person 
                            consultation with Dr. Pooja Reddy. We'll perform an in-depth skin and hair analysis.</p>
                       </div>

                       <div className="flex flex-col gap-2">
                            <h3 className="text-center p-[15px]"><strong>Personalized Treatment Plan</strong></h3>
                            <p className="pl-4">We'll provide a clear 6-month roadmap, including weekly planning and service scheduling.</p>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowItWorks;