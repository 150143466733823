
import "./testimonial.css";
// import {Testimonials as testimonials} from "./testimonialsContent";

import { testimonials } from "./testimonialsContent";

function Testimonials() {
  return (
    <>
      <div className="h-full w-full section-hero_component pt-[32px]">
        <div className="flex justify-start">
          <h2 className="pl-8 font-bold text-2xl max-h-12 min-h-12">
            What our customers say
          </h2>
        </div>

        <div
          id="testimonalCards scroller"
          className="slider h-screen overflow-hidden"
          style={{
            "--height": "70%",
            "--width": "200px",
            "--quantity": "3",
            "--duration": "15s",
          }}
        >
          <div className="list">
            {testimonials.map((group, groupIndex) => {
              return (
                <>
                  {[...group].map(
                    (
                        client, index
                    // eslint-disable-next-line array-callback-return
                    ) => {
                      if (groupIndex === 0) {
                        return (
                          <>
                            <div
                              id="topRow"
                              className="item"
                              style={{
                                "--position": index + 1,
                                "--randomNumber": index % 2,
                              }}
                            >
                              <div
                                className="border-0 relative rounded-[20px] w-full h-full 
                                                    max-w-[200px] bg-[#5d7599] overflow-hidden"
                              >
                                <img className="image" src={client.image} alt={client.image} />
                                <div
                                  className="text-content bottom-0 h-1/5 p-[5px] w-full rounded-[20px]
                                                        border-0 text-[9px] bg-[#fcfcfc] backdrop-blur- overflow-scroll break-words"
                                  style={{
                                    scrollbarWidth: "none",
                                    scrollBehavior: "smooth",
                                  }}
                                >
                                  <p>{client.review}</p>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      }

                      if (groupIndex === 1) {
                        return (
                          <>
                            <div
                              id="bottomRow"
                              className="item"
                              style={{
                                "--position": index + 1,
                                "--randomNumber": index % 2,
                              }}
                            >
                              <div
                                className="border-0 relative rounded-[20px] w-full h-full 
                                                     max-w-[200px] bg-[#5d7599] overflow-hidden"
                              >
                                <img className="image" src={client.image} alt={client.image} />
                                <div
                                  className="text-content bottom-0 h-1/5 p-[5px] w-full rounded-[20px] 
                                                        border-0 text-[9px] bg-[#fcfcfc] overflow-scroll break-words"
                                  style={{
                                    scrollbarWidth: "none",
                                    scrollBehavior: "smooth",
                                  }}
                                >
                                  <p>{client.review}</p>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      }
                    }
                  )}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonials;
